import React from "react";
import Logo from "../assets/img/logo2.jpg";
import TrustLogo from "../assets/img/Trustpilot_logo.jpg";
import TrustStars from "../assets/img/Layer 6.jpg";
function Header() {
    return (
        <div className="header">
            <div className="text-center">
                <img src={Logo} alt="MeerVoordeel" className="header__logo" />
            </div>
            <div className="text-center text-sm-right mt-3 mt-sm--5">
                <img
                    src={TrustLogo}
                    alt="TrustPilot"
                    className="header__trust mr-4"
                />
                <br />
                <img
                    src={TrustStars}
                    alt="TrustRating"
                    className="header__rating"
                />
            </div>
        </div>
    );
}

export default Header;
